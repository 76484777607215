import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";

export default {
	init () {

		Fancybox.defaults.Hash = false;

		//$.fancybox.defaults.Thumbs = { autoStart : true };
		Fancybox.defaults.Thumbs = { autoStart : false };
		
		Fancybox.bind("[data-fancybox]", {
		Thumbs: {
			autoStart: false,
		},
		});

		Fancybox.bind('[data-fancybox*="solutions"]', {
		Thumbs: {
			autoStart: false,
		}
		});

	},
}