import $ from 'jquery';

export default {
	init() {
		$('html').addClass('js');

		// $('.home-news-post h3').matchHeight({ property: 'min-height' });

		// var widest = 0;
		// $(".fusion-checklist.align-center .fusion-li-item-content").each(function () { widest = Math.max(widest, $(this).outerWidth()); }).width(widest);
		// $(".fusion-checklist.align-center")
	},
};
