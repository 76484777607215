import $ from 'jquery';

// jquery match height NMP
import jqueryMatchHeight from 'jquery-match-height';

// Custom Modules
import general from './modules/general';
import fancybox from './modules/fancybox';

document.addEventListener('DOMContentLoaded', function () {
	general.init();
	//fancybox.init();
  }, false);